export default [
  {
    path: '/livestock-dashboard',
    name: 'livestock-dashboard',
    component: () => import('@/views/liveStock/Dashboard.vue'),
  },
  {
    path: '/manage-livestock-and-poultry',
    name: 'manage-livestock-and-poultry',
    component: () => import('@/views/liveStock/RegisterLiveStock.vue'),
  },
  {
    path: '/track-livestock-growth',
    name: 'track-livestock',
    component: () => import('@/views/liveStock/TrackSeasonalGrowth.vue'),
  },
  {
    path: '/livestock-history',
    name: 'livestock-history',
    component: () => import('@/views/liveStock/History.vue'),
  },
]
