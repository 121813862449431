export default [
  {
    path: '/settings/user-accounts',
    name: 'user-accounts',
    component: () => import('@/views/settings/UserAccounts.vue'),
  },
  {
    path: '/settings/expense-types',
    name: 'expense-types',
    component: () => import('@/views/settings/ExpenseTypes.vue'),
  },
  {
    path: '/settings/income-types',
    name: 'income-types',
    component: () => import('@/views/settings/IncomeTypes.vue'),
  },
  {
    path: '/settings/farm-produce',
    name: 'farm-produce',
    component: () => import('@/views/settings/FarmProduce.vue'),
  },
  {
    path: '/settings/farm-stock',
    name: 'farm-stock',
    component: () => import('@/views/settings/FarmStockItem.vue'),
  },
  {
    path: '/settings/item-units',
    name: 'item-units',
    component: () => import('@/views/settings/ItemUnits.vue'),
  },
  {
    path: '/settings/live-stock-type',
    name: 'live-stock-type',
    component: () => import('@/views/settings/LiveStockName.vue'),
  },
]
