export default [
  {
    path: '/inventory/stock-in',
    name: 'inventory-stockin',
    component: () => import('@/views/Inventory/StockIn.vue'),
  },
  {
    path: '/inventory/stock-out',
    name: 'inventory-stockout',
    component: () => import('@/views/Inventory/StockOut.vue'),
  },
  {
    path: '/inventory/stock',
    name: 'stock',
    component: () => import('@/views/Inventory/Inventory.vue'),
  },
  {
    path: '/inventory/details',
    name: 'stock-details',
    component: () => import('@/views/Inventory/InventoryDetails.vue'),
  },
]
