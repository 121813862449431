export default [
  {
    path: '/register-produce',
    name: 'register-produce',
    component: () => import('@/views/produce/RegisterProduce.vue'),
  },
  {
    path: '/track-produce-growth',
    name: 'track-produce',
    component: () => import('@/views/produce/TrackSeasonalGrowth.vue'),
  },
  {
    path: '/produce-history',
    name: 'produce-history',
    component: () => import('@/views/produce/History.vue'),
  },
]
